/* ContactPage.css */
.content{
    background-color: rgba(0, 0, 0, 0.963);
    background-size: cover; /* Ensures the image covers the entire section */
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional: Adds a subtle border */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color change */
}
.about-banner {
    background-color: rgba(0, 0, 0, 0.963);
    background-size: cover; /* Ensures the image covers the entire section */
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional: Adds a subtle border */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color change */
    background-image: url('../assets/img/011220.png'); 
    padding: 100px 0;
}

.contact-bg {
    background-color: rgba(0, 0, 0, 0.963);
    background-size: cover; /* Ensures the image covers the entire section */
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional: Adds a subtle border */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color change */
}

.talk-bg {
    background-color: rgba(0, 0, 0, 0.963);
    background-size: cover; /* Ensures the image covers the entire section */
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional: Adds a subtle border */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color change */
    color: #fff;
    padding: 50px 0;
}

.footer-bg {
    background-color: rgba(0, 0, 0, 0.963);
    background-size: cover; /* Ensures the image covers the entire section */
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional: Adds a subtle border */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color change */
}

.footer-icon a {
    color: #333;
    margin-right: 10px;
}

.footer-menu a {
    color: #333;
}

.contact-form .form-control {
    margin-bottom: 20px;
}

.contact-form .btn {
    background-color: #007bff;
    border: none;
    color: #fff;
}
.bg-contern{
    background-image: url('../assets/img/011220.png'); 
}
.pe-1{
    margin-bottom: 1.5%;
}

