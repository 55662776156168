/* TestimonialArea.css */

.testimonialArea {
  background-color: rgba(0, 0, 0, 0.963);
  background-image: url('../assets/img/011220.png'); /* Corrected to use url() */
  background-size: cover; /* Ensures the image covers the entire section */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional: Adds a subtle border */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color change */
  padding: 5rem 0; /* Adjust padding as needed */
}

.testimonial-card {
  background-color: rgba(250, 245, 245, 0.064); /* Adjust the alpha value for transparency */
  backdrop-filter: blur(10px); /* Optional: Adds a blur effect behind the card */
  border-bottom: 1px solid rgba(255, 255, 255, 0.061); /* Optional: Adds a subtle border */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color change */
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
}

.testimonial-img img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.testimonial-icon {
  margin: 1rem 0;
}

.testimonial-icon i {
  color: #f39c12;
  margin: 0 2px;
}

@media (max-width: 576px) {
  .testimonial-card {
    padding: 1rem;
  }
}
