/* src/components/ReservationForm.css */
.reservationForm {
  background-color: rgba(0, 0, 0, 0.963);
  background-size: cover; /* Ensures the image covers the entire section */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional: Adds a subtle border */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color change */
}

.bg-one {
  padding: 5rem 0;
}

.tool-box {
  display: flex;
  background-color: #0e0e0e;
  border-radius: 2%;
  padding: 20px;
}

.text-center {
  color: white;
}

.form-select,
.form-control {
  width: 100%;
  border-radius: 5px;
  padding: 0.75rem;
  margin-bottom: 1rem;
}

.form-select {
  border: 1px solid #f5f7f9;
}

.form-control {
  border: 1px solid #f0f2f4;
}

.btn-2 {
  margin-bottom: 38px;
  background-color: #007bff;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  cursor: pointer; /* Adds a pointer cursor for the button */
  transition: background-color 0.3s ease; /* Smooth transition for background color change */
  width: 200px;
}

.btn-2:hover {
  background-color: #0056b3;
}

.rent-option {
  display: flex;
  flex-direction: column; /* Default to column layout */
  align-items: center; /* Center-align items within this class */
  gap: 1rem; /* Space between items */
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .rent-option {
    flex-direction: row; /* Change to row layout for larger screens */
    align-items: center; /* Center items vertically within the row */
    justify-content: center; /* Center items horizontally */
    width: 100%; /* Ensure it takes full width */
  }
}

@media (max-width: 576px) {
  .btn-2 {
    width: 100%; /* Full width button on small screens */
  }
  .rent-option {
    flex-direction: column; /* Revert to column layout for medium screens */
    align-items: center; /* Center-align items in column layout */
    justify-content: center;
    width: 100%;
  }
  .form-select,
  .form-control {
    width: 100%; /* Full width form controls on small screens */
  }
}

@media (max-width: 768px) {
  .rent-option {
    flex-direction: column; /* Revert to column layout for medium screens */
    align-items: center; /* Center-align items in column layout */
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .btn-2 {
    width: 250px; /* Adjust button width for tablets and small laptops */
  }
  .rent-option {
    flex-direction: column; /* Revert to column layout for medium screens */
    align-items: center; /* Center-align items in column layout */
    justify-content: center;
  }
}

.title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.fw-bold {
  font-weight: bold;
}

/* Styles for Autocomplete placeholder */
.MuiAutocomplete-input {
  color: white; /* Change input text color */
}

.MuiAutocomplete-input::placeholder {
  color: rgba(255, 255, 255, 0.7); /* Change placeholder color */
}

/* New Styles for Response Section */
#response-container {
  background-color: #fff; /* Background color for the response container */
  border-radius: 5px; /* Rounded corners */
  padding: 1rem; /* Padding around the response text */
  margin-top: 1rem; /* Space above the response container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for visual separation */
}

#response {
  white-space: pre-wrap; /* Preserve whitespace and line breaks */
  word-wrap: break-word; /* Break long words to prevent overflow */
  font-family: monospace; /* Monospaced font for code-like appearance */
  color: #333; /* Text color for better readability */
  margin: 0; /* Remove default margin */
}

.box {
  width: 100px;
  height: 100px;
  background-color: #000; /* Optional: Add a background color */
}

#popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Make sure it's on top of other content */
}

.popup-content {
  background-color: rgba(0, 0, 0, 0.487);
  background-size: cover; /* Ensures the image covers the entire section */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional: Adds a subtle border */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color change */
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.popup-content h4 {
  color: white;
  margin: 0 0 10px;
}

.popup-content p {
  color: white;
  margin: 0 0 20px;
}

.popup-content button {
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;

  text-decoration: underline;
}

/* Mobile Responsive Styles */
@media (max-width: 576px) {
  .reservationForm {
    padding: 2rem; /* Add padding for better spacing on small screens */
  }

  .bg-one {
    padding: 2rem 0; /* Adjust padding for smaller screens */
  }

  .tool-box {
    flex-direction: column; /* Stack items vertically */
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .btn-2 {
    width: 100%; /* Full width button on small screens */
    margin-bottom: 1rem; /* Space below the button */
  }

  .rent-option {
    flex-direction: column; /* Column layout for small screens */
    gap: 0.5rem; /* Reduce gap between items */
    width: 100%; /* Ensure it takes full width */
  }

  .form-select,
  .form-control {
    width: 100%; /* Full width form controls */
    margin-bottom: 1rem; /* Space between form controls */
  }

  #response-container {
    padding: 0.5rem; /* Less padding for smaller screens */
    font-size: 0.9rem; /* Adjust font size */
  }

  .popup-content {
    padding: 15px; /* Reduce padding for mobile view */
    width: 90%; /* Allow popup to take more width */
    max-width: none; /* Remove max-width constraint */
  }

  .popup-content h4 {
    font-size: 1.5rem; /* Adjust font size */
  }

  .popup-content p {
    font-size: 0.9rem; /* Adjust font size */
  }
}


