.car-details {
  background-color: rgba(0, 0, 0, 0.963); /* Adjust the alpha value for transparency */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional: Adds a subtle border */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color change */
  color: rgba(255, 255, 255, 1);
}

.car-details-banner {
  background-image: url('../assets/img/011220.png');
  background-size: cover;
  background-position: center;
}

.car-menu {
  background-color: rgba(255, 255, 255, 0.089);
  border-bottom: 3px solid rgba(255, 255, 255, 0.1); 
  transition: background-color 0.3s ease, color 0.3s ease;
  padding: 10%;
  border-radius: 3%;
}

.menu-btn {
  display: flex;
  flex-direction: column;
}

.button-design button {
  background-color: #007bff; /* Example button color */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%; /* Make buttons full width on smaller screens */
  margin-bottom: 10px; /* Add space between buttons on mobile */
}

.button-design button:hover {
  background-color: #0056b3; /* Hover color */
}

.car-icons {
  font-size: 1.2rem; /* Adjust icon size */
}

.menu-btn .button-design {
  margin-right: 0; /* Remove right margin on mobile */
}

@media (min-width: 768px) {
  .menu-btn .button-design {
      margin-right: 10px; /* Add right margin back on larger screens */
  }

  .button-design button {
      width: auto; /* Reset button width on larger screens */
  }

  .menu-btn {
      flex-direction: row; /* Display buttons in a row on larger screens */
  }

  .button-design button {
      margin-bottom: 0; /* Remove space between buttons on larger screens */
  }
}

.list-unstyled {
  padding-left: 0; /* Remove default padding */
  margin-left: 0; /* Remove default margin */
  list-style-type: none; /* Remove bullet points */
}

.list-icon {
  display: flex; /* Center icon vertically */
  align-items: center; /* Center icon vertically */
}

.list-text {
  margin: 0; /* Remove default margin */
}

.d-flex {
  display: flex; /* Ensure flex display for alignment */
  align-items: center; /* Center items vertically */
  /* Add some space between list items */
}

.text-start {
  text-align: left; /* Align text to the left */
}

.list-unstyled {
  padding-left: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.list-text,
.list-textC {
  margin: 0; /* Ensure there's no margin that affects alignment */
}

.d-flex {
  display: flex;
  align-items: center; /* Align items vertically center */
}

.align-items-start {
  align-items: flex-start; /* Align items to the start of the flex container */
}

.text-start {
  text-align: left; /* Align text to the left */
}
