.section-inf {
  background-color: rgba(0, 0, 0, 0.963); /* Adjust the alpha value for transparency */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional: Adds a subtle border */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color change */
  padding-top: 5rem;
  padding-bottom: 5rem;
  font-family: "Roboto", sans-serif;
}
.info-data{
  margin-top:3%;
}
.info-text {
  text-align: left;
  color: #fef1f1;
}

.info-text .subheading {
  color: #fef1f1;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.info-text .heading {
  color: #eceaea;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.info-text .description {
  color: #f1ebeb;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.info-text .why-choose-heading {
  color: #f7ecec;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.info-text .benefits-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.info-text .benefits-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  color: white;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.info-text .benefits-list li {
  margin-bottom: 1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.info-text .benefits-list li i {
  color: #007bff;
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.image-container {
  position: relative;
  }

.image-container img {
  max-width: 100%;
  height: auto;
  display: block;
}

.video-btn {
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  border: none;
  width: 60px;
  height: 60px; /* Ensure the button is a perfect circle */
  padding: 0.65rem;
  border-radius: 50%;
  font-size: 1.5rem;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.video-btn:hover {
  background-color: #ff0000;
  color: #fff;
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
}

.modal-body iframe {
  width: 100%;
  height: 500px;
}

.check-section{
  display: flex;
  margin-top: 30px;
}
.check1{
  margin: 10px;
}

/* Responsive Design */
@media (max-width: 992px) {
  .info-text .heading {
    font-size: 2rem;
  }

  .info-text .why-choose-heading {
    font-size: 2.5rem;
  }

  .info-text .description {
    font-size: 0.9rem;
  }

  .video-btn {
    width: 50px;
    height: 50px;
    font-size: 1.2rem;
  }

  .modal-body iframe {
    height: 400px;
  }
  .check-section{
    display: flex;
  }
  .check{
    flex: 2;
  }
  .image-view{
    margin-top: 20%;
  }
}

@media (max-width: 768px) {
  .section-inf {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  

  .info-text .heading {
    font-size: 2rem;
  }

  .info-text .why-choose-heading {
    font-size: 1.25rem;
  }

  .info-text .description {
    font-size: 0.8rem;
  }

  .video-btn {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }

  .modal-body iframe {
    height: 300px;
  }
  .check-section{
    display: flex;
  }
  .check{
    flex: 2;
  }
  .image-view{
    margin-top: 20%;
  }
}

@media (max-width: 576px) {
 .info-text .subheading{
  font-size: 1rem;
  padding: 2px;
 }

  .info-text .heading {
    font-size: 2rem;
  }

  .info-text .why-choose-heading {
    font-size: 1.5rem;
  }

  .info-text .description {
    font-size: 0.75rem;
  }

  .video-btn {
    width: 35px;
    height: 35px;
    font-size: 0.9rem;
  }

  .modal-body iframe {
    height: 250px;
  }
  .check-section{
    display: flex;
  }
  .check{
    flex: 2;
  }
  .check1{
    margin: 1.5px;
  }
  .image-view{
    margin-top: 20%;
  }
}
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background: rgba(255, 255, 255, 0.208);
  border-radius: 8px;
  overflow: hidden;
  width: 80%;
  max-width: 800px;
  position: relative;
  padding: 20px;
}

/* Close Button */
.close-button {
  color: #4011da;
  position: absolute;
  top: -10px;
  right: 1px;
  background: none;
  border: none;
  font-size: 2.5rem;
  cursor: pointer;
}