/* VehicelFeatures.css */

.Feature {
  padding: 2rem 1rem;
  background-color: rgba(0, 0, 0, 0.963);
  background-size: cover; /* Ensures the image covers the entire section */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional: Adds a subtle border */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color change */
}

.title {
  font-size: 1.2rem;
  color: #fffefe;
}

.pb-5 {
  padding-bottom: 3rem;
}

.fw-bold {
  font-weight: bold;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.d-flex > div {
  margin: 1rem;
}

@media (max-width: 768px) {
  .d-flex {
      flex-direction: column;
      align-items: center;
  }

  .title {
      font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .pb-5 {
      padding-bottom: 2rem;
  }

  .d-flex > div {
      margin: 0.5rem;
  }
}
