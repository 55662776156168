/* LetsTalkArea.css */
.lestsTalkArea{
    background-color: rgba(0, 0, 0, 0.963);
    background-size: cover; /* Ensures the image covers the entire section */
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional: Adds a subtle border */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color change */
    color: #fff;
}
.talk-bg {
    background-color: #f8f9fa0d; /* Adjust the background color as needed */
    
    padding: 3rem 0; /* Adjust padding as needed */
  }
  
  .title {
    color: #f6f3f3; /* Adjust the title color */
    font-size: 1.5rem; /* Adjust the title font size */
  }
  
  .fw-bold {
    font-weight: 700;
  }
  
  .lets-btn {
    justify-content: center;
  }
  
  .button-design .btn-3 button {
    background-color: #007bff; /* Adjust button background color */
    color: #fff; /* Adjust button text color */
    padding: 0.75rem 1.5rem;
    border-radius: 0.25rem;
    border: none; /* Remove the default button border */
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
    font-size: 1rem; /* Make the button text size the same as the link text */
  }
  
  .button-design .btn-3 button:hover {
    background-color: #0056b3; /* Adjust button hover background color */
  }
  
  .contact-area {
    background-color: rgba(0, 0, 0, 0.963);
    background-size: cover; /* Ensures the image covers the entire section */
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional: Adds a subtle border */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color change */
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    color: white;
    
  }
  
  .contact {
    align-items: center;
  
  }
  
  .contact i {
    font-size: 2rem; /* Adjust icon size */
    color: #007bff; /* Adjust icon color */
  }
  
  .contact .div {
    padding-left: 1rem;
    
  }
  
  .contact .fw-bold.h5 {
    font-size: 1.25rem; /* Adjust contact title font size */
    color: #333; /* Adjust contact title color */
  }
  
  .contact .con-num {
    color: #777; /* Adjust contact info color */
  }
  