.nationality-selection {
    background-color: rgba(255, 255, 255, 0.316); /* Slightly transparent white */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
    transition: transform 0.3s ease; /* Smooth transition on hover */
  }
  
  .nationality-selection:hover {
    transform: scale(1.02); /* Slight zoom effect on hover */
  }
  
  .nationality-selection h5 {
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .nationality-selection button {
    width: 100%; /* Full width buttons */
    padding: 10px;
    margin: 5px 0; /* Space between buttons */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  