.loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(7, 0, 0); /* Optional: Add a semi-transparent background */
    z-index: 9999;
  }
  
