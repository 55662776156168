:root {
  --clr-neon: rgba(219, 210, 210, 0.719);
  --clr-bg: rgb(12, 12, 12);
  --clr-white: rgba(233, 229, 229, 0.7);
}

.swiper-container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.963); /* Adjust the alpha value for transparency */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional: Adds a subtle border */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color change */
}

.swiper-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-slide {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.slide-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.slide-content {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-align: center;
  color: #f2f0f0;
  position: absolute;
  padding: 30px;
  bottom: 35%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  font-size: 12px;

}

.slide-content h1 {
  font-size: 5em;
  margin-bottom: 10px;
}

.slide-content p {
  font-size: 18px;
}

.rent-price {
  font-weight: bold;
  color: #ff0000;
}

.slide-content button {

  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  padding: 0.5em 3em;
  border-radius: 1em;
  position: relative;
  background-color: #f9f9f96a;
  border: 0 ;
  font-weight: 600;
  font-size: 2em;
}

.slide-content button::before {
  pointer-events: none;
  content: "";
  position: absolute;
  border-radius: 1em;
  top: 120%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: perspective(1.2em) rotateX(40deg) scale(1.5, 0.5);
  filter: blur(1.15em);
  opacity: 0.2;
  transition: transform 0.5s linear;
  border:0;
}

.slide-content button::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 4em 0.6em var(--clr-neon), 0 0 1em 0.2em var(--clr-white);
  opacity: 0;
  background: var(--clr-neon);
  z-index: -1;
  transition: opacity 0.5s linear;
  border-radius: 1em;
}

.slide-content button:hover,
.slide-content button:focus {
  color: var(--clr-bg);
  text-shadow: none;
}

.slide-content button:hover::before,
.slide-content button:focus::before {
  opacity: 1;
  transform: perspective(1em) rotateX(40deg) scale(1.5, 0.6);
  transition: transform 0.5s linear;
}

.slide-content button:hover::after,
.slide-content button:focus::after {
  opacity: 1;
}

.swiper-pagination-bullet {
  background: #007bff;
}

.swiper-pagination-bullet-active {
  background: #0056b3;
}

.swiper-button-next {
  right: 10px;
}

.swiper-button-prev {
  left: 10px;
}

/* Responsive design tweaks */
@media (max-width: 1200px) {
  .slide-content {
    width: 80%;
    bottom:32%;
    font-size: 10px;
  }

  .slide-content h1 {
    font-size: 45px;
  }
}

@media (max-width: 992px) {
  .slide-content {
    width: 80%;
    bottom:22%;
    font-size: 10px;
  }

  .slide-content h1 {
    font-size: 45px;
  }

  .slide-content p {
    font-size: 16px;
  }

  .swiper-button-next, .swiper-button-prev {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 768px) {
  .slide-content {
    width: 80%;
    bottom: 10%;
    font-size: 9px;
  }

  .slide-content h1 {
    font-size: 30px;
  }

  .slide-content p {
    font-size: 16px;
  }

  .swiper-button-next, .swiper-button-prev {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .slide-content {
    width: 80%;
    height: 160px;
    font-size: 12px;
  }

  .slide-content h1 {
    font-size: 30px;
  }

  .slide-content p {
    font-size: 15px;
  }

  .slide-content button {
    padding: 6px 15px;
    font-size: 15px;
  }

  .swiper-button-next, .swiper-button-prev {
    width: 20px;
    height:20px;
  }
}
