/* FooterArea.css */

.FooterArea{
  background-color: rgba(0, 0, 0, 0.963);
  background-size: cover; /* Ensures the image covers the entire section */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional: Adds a subtle border */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color change */
  color: #ffffff; /* Adjust the text color */
}

  
  .footer-bg .h1 {
    color: #f8f9fa; /* Adjust the title color */
  }
  
  .footer-icon a {
    color: #ffffff; /* Adjust icon color */
    font-size: 1.5rem;
    margin: 0 0.5rem;
    transition: color 0.3s ease;
  }
  
  .footer-icon a:hover {
    color: #007bff; /* Adjust hover color */
  }
  
  .footer-menu h4 {
    color: #f8f9fa; /* Adjust menu title color */
  }
  
  .footer-menu a {
    color: #f8f9fa; /* Adjust link color */
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-menu a:hover {
    color: #007bff; /* Adjust hover color */
  }
  
  .form-bg2 {
    background-color: #ffffff; /* Adjust form background color */
    border-radius: 0.25rem;
  }
  
  .blog-page {
    border-radius: 0.25rem;
  }
  
  .footer-page-btn {
    background-color: #007bff; /* Adjust button background color */
    color: #ffffff; /* Adjust button text color */
    border: none;
    border-radius: 0.25rem;
  }
  
  .footer-page-btn:hover {
    background-color: #0056b3; /* Adjust button hover background color */
  }
  
  .text-center {
    color: #f8f9fa; /* Adjust copyright text color */
  }
  .footer{
    padding-bottom:2%;
  }
  