/* Navbar styling */
.navbar-transparent {
  background-color: rgba(0, 0, 0, 0.426);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar-fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.navbar-brand {
  font-size: 2.5em;
  font-family: "Roboto", sans-serif;
}

.navbar-nav {
  margin-left: auto;
}

.navbar-nav .nav-item {
  font-size: large;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  margin-right: 1rem;
}

.navbar-brand,
.navbar-nav .nav-link {
  color: #fff;
}

.navbar-brand:hover,
.navbar-nav .nav-link:hover {
  color: #ddd;
}

.navbar-scrolled {
  background-color: rgba(224, 217, 217, 0.8);
}

.navbar-scrolled .navbar-brand,
.navbar-scrolled .nav-link {
  color: #000;
}

.navbar-scrolled .navbar-brand:hover,
.navbar-scrolled .nav-link:hover {
  color: #e2d8d8;
}

.navbar-nav .nav-link.active {
  color: #ffffff;
  font-weight: bold;
}

/* Responsive design tweaks */
@media (max-width: 1200px) {
  .navbar-nav {
    margin-left: auto;
    margin-right: 2%;
  }
}

@media (max-width: 992px) {
  .navbar-nav {
    margin-left: auto;
    margin-right: 2%;
  }
}

@media (max-width: 768px) {
  .navbar-nav {
    margin-left: auto;
    margin-right: 0;
    text-align: center;
  }
  .navbar-nav .nav-item {
    margin-right: 0;
    display: block;
    font-size: 1.2em;
  }
  .navbar-brand {
    font-size: 2em;
  }
}

@media (max-width: 420px) {

  .navbar-brand {
    font-size: 1.8em;
    margin-left: 1%;
  }
  
  .navbar-nav {
    margin-left: auto;
    margin-right: 0;
    padding: 0;
    text-align: center;
  }
  
  .navbar-nav .nav-item {
    font-size: 1em;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}
