/* CounterArea.css */

.counter {
    background-color: #f8f9fa;
    padding: 2rem 0;
  }
  
  .counter-number {
    font-size: 2.5rem;
    font-weight: bold;
    color: #007bff;
  }
  
  .counter-title {
    font-size: 1.2rem;
    color: #333;
    margin-top: 0.5rem;
  }
  
  @media (max-width: 576px) {
    .counter-number {
      font-size: 2rem;
    }
  
    .counter-title {
      font-size: 1rem;
    }
  }
  